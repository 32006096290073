import { config } from "@config";

export default {
  coursesList: true,
  roleSwitching: true,
  branchesList: true,
  courseUnits: true,
  singleUnitReports: true,
  usersList: true,
  groups: true,
  widgetsDashboard: true,
  reports: true,
  accountAndSettings: true,
  courseReports: true,
  userReports: true,
  groupReports: true,
  branchReports: true,
  accountsAndSettingsSubmenus: true,
  eventsEngine: true,
  showSubscriptionTab: true,
  customReports: true,
  branchCreateUpdate: true,
  helpCenter: true,
  customHomePage: true,
  communications: true,
  showEcommerce: true,

  // NOT_PRODUCTION_READY
  unitUsersMassActions: !config.isProd(),
  learningActivitiesOverview: !config.isProd(),
  branchOverview: !config.isProd(),
} as const;
